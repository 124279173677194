const footerLinks = [
   {
      category: "1",
      links: [
         {
            label: "For Students",
            href: "/students",
         },
         {
            label: "For Companies",
            href: "/",
         },
         {
            label: "For Career Centers",
            href: "/career-center",
         },
      ],
   },
   {
      category: "2",
      links: [
         {
            label: "Terms and Conditions",
            href: "/terms",
         },
         {
            label: "Privacy Policy",
            href: "/privacy",
         },
         {
            label: "Cookie Policy",
            href: "/cookies",
         },
      ],
   },
   {
      category: "3",
      links: [
         {
            label: "About us",
            href: "/about-us",
         },
         {
            label: "Companies",
            href: "/companies",
         },
         {
            label: "Wishlist",
            href: "/wishlist",
         },
      ],
   },
]

export default footerLinks
