import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

const icons = [
   {
      icon: <LinkedInIcon />,
      href: "https://www.linkedin.com/company/careerfairy/",
   },
   {
      icon: <FacebookIcon />,
      href: "https://www.facebook.com/careerfairy",
   },
   {
      icon: <InstagramIcon />,
      href: "https://www.instagram.com/careerfairy/",
   },
];

export default icons;
