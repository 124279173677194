import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import { Box, Container, Grid, Typography } from "@mui/material";
import Link from "next/link";
import footerLinks from "./footerLinks";
import icons from "./icons";

const FooterV2 = ({ background }) => {
   const theme = useTheme();
   const greyColor = alpha(theme.palette.text.secondary, 0.5);

   return (
      <Box
         sx={{
            background: (theme) => background || theme.palette.common.white,
         }}
      >
         <Container
            sx={(theme) => ({
               color: greyColor,
               "& > *": {
                  marginBottom: theme.spacing(3),
               },
            })}
         >
            <div>
               <Typography
                  align="center"
                  sx={{
                     marginTop: (theme) => theme.spacing(2),
                     color: "inherit",
                  }}
                  variant="h6"
               >
                  LIVE STREAMING CAREER INSPIRATION
               </Typography>
            </div>
            <div>
               <Grid justifyContent="center" container>
                  {footerLinks.map(({ links, category }) => (
                     <Grid key={category} item xs={12} sm={4} md={3} lg={2}>
                        {links.map(({ label, href }) => (
                           <Box
                              key={href}
                              sx={(theme) => ({
                                 display: "flex",
                                 justifyContent: "center",
                                 padding: theme.spacing(1),
                                 "& a": {
                                    color: "inherit",
                                    borderColor: "inherit",
                                    borderBottom: "2px solid",
                                    "&:hover": {
                                       color: theme.palette.primary.main,
                                    },
                                 },
                              })}
                           >
                              <Link href={href}>
                                 <a>{label}</a>
                              </Link>
                           </Box>
                        ))}
                     </Grid>
                  ))}
               </Grid>
            </div>
            <Box
               sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
               }}
            >
               {icons.map(({ icon, href }) => (
                  <Box
                     component="a"
                     sx={(theme) => ({
                        padding: theme.spacing(0, 3),
                        "& .MuiSvgIcon-root": {
                           fontSize: theme.spacing(4),
                           color: greyColor,
                           "&:hover": {
                              color: theme.palette.primary.main,
                           },
                        },
                     })}
                     href={href}
                     key={href}
                     target="_blank"
                     rel="noopener
                            noreferrer"
                  >
                     {icon}
                  </Box>
               ))}
            </Box>
            <div>
               <Typography align="center">
                  2021 - CareerFairy AG - Made in Zurich, Switzerland - Contact:
                  <Box
                     component="a"
                     sx={{
                        marginLeft: 5,
                     }}
                     href="mailto:info@careerfairy.io"
                  >
                     info@careerfairy.io
                  </Box>
               </Typography>
            </div>
         </Container>
      </Box>
   );
};

export default FooterV2;
